import styles from "./AppLayout.module.css";

import { ReactNode, StrictMode } from "react";
import AppHeader from "@/components/AppHeader";
import ThemeProvider from "@/providers/ThemeProvider.tsx";

import "@/reset.css";
import "@/index.css";

import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";
import { MultitoolBrowser } from "multitool-sdk-browser";
import { Head } from "vike-react/Head";
import { env, userId } from "@/api/config";

const MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID = import.meta.env
  .MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID;

MultitoolBrowser.init({
  env: env,
  user: userId,
});

export default function App({ children }: { children: ReactNode }) {
  return (
    <StrictMode>
      <GoogleTagManagerProvider id={MULTITOOL_CLIENT_GOOGLE_TAG_MANAGER_ID}>
        <ThemeProvider>
          <div className={styles.app}>
            <Head>
              <meta name="robots" content="index, follow" />
              <meta name="author" content="Multitool.xyz" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Head>

            <AppHeader />

            <main className={styles.main}>{children}</main>
          </div>
        </ThemeProvider>
      </GoogleTagManagerProvider>
    </StrictMode>
  );
}
