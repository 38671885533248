import styles from "./Nav.module.css";

import React from "react";
import { CaretDownIcon } from "@radix-ui/react-icons";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";

type Link = {
  title: string;
  description?: string;
  href?: string;
  children?: Link[];
};

const links: Link[] = [
  {
    title: "Image",
    children: [
      {
        title: "Grayscale",
        description: "Turn any image black and white",
        href: "/image/grayscale",
      },
      {
        title: "Magick",
        description: "Turn any image magical",
        href: "/image/magick",
      },
    ],
  },
  {
    title: "Math",
    children: [
      {
        title: "Add",
        description: "Put two exciting numbers together",
        href: "/math/add",
      },
      {
        title: "Subtract",
        description: "Take one number away from another",
        href: "/math/subtract",
      },
    ],
  },
  {
    title: "Units",
    children: [
      {
        title: "Convert",
        description: "Convert some units",
        href: "/unit/convert",
      },
    ],
  },
  {
    title: "About",
    href: "/about",
    children: [
      {
        title: "About Multitool",
        href: "/about",
      },
      {
        title: "Privacy Policy",
        href: "/privacy",
      },
      {
        title: "Terms",
        href: "/terms",
      },
      {
        title: "Cookie Policy",
        href: "/cookies",
      },
    ],
  },
];

const Nav = () => {
  return (
    <NavigationMenu.Root className={styles.NavigationMenuRoot}>
      <NavigationMenu.List className={styles.NavigationMenuList}>
        {links.map((link) => (
          <NavigationMenu.Item key={link.title}>
            {link.children && (
              <>
                <NavigationMenu.Trigger
                  className={styles.NavigationMenuTrigger}
                >
                  {link.title}{" "}
                  <CaretDownIcon className={styles.CaretDown} aria-hidden />
                </NavigationMenu.Trigger>
                <NavigationMenu.Content
                  className={styles.NavigationMenuContent}
                >
                  <ul className={styles.List}>
                    {link.children?.map((child) => (
                      <ListItem
                        key={child.title}
                        href={child.href}
                        title={child.title}
                      >
                        {child.description ?? ""}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenu.Content>
              </>
            )}
            {!link.children && (
              <NavigationMenu.Link
                className={styles.NavigationMenuLink}
                href={link.href}
              >
                {link.title}
              </NavigationMenu.Link>
            )}
          </NavigationMenu.Item>
        ))}

        <NavigationMenu.Indicator className={styles.NavigationMenuIndicator}>
          <div className={styles.Arrow} />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className={styles.ViewportPosition}>
        <NavigationMenu.Viewport className={styles.NavigationMenuViewport} />
      </div>
    </NavigationMenu.Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }: any, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames(styles.ListItemLink, className)}
          {...props}
          ref={forwardedRef}
        >
          <div className={styles.ListItemHeading}>{title}</div>
          <p className={styles.ListItemText}>{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);

export default Nav;
