import styles from "./Logo.module.css";

import multitool from "@/assets/multitool.svg";

const Logo: React.FC = () => {
  return (
    <a href="/" className={styles.logo}>
      <img src={multitool} alt="Multitool logo" />
      <span>Multitool</span>
    </a>
  );
};

export default Logo;
