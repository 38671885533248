import { v4 as uuidv4 } from "uuid";

const USER_ID_KEY = "multitool-userId";
export const userId = setupUserId();
export const env = import.meta.env.MULTITOOL_CLIENT_ENV;

function setupUserId(): string {
  if (typeof localStorage === "undefined") {
    // This is only hit during server-side rendering because we call setupUserId() when the file is imported.
    return "SERVER";
  }

  let userId = localStorage.getItem(USER_ID_KEY);
  if (userId) {
    return userId;
  } else {
    userId = uuidv4();
    localStorage.setItem(USER_ID_KEY, userId);
    return userId;
  }
}
