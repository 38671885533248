import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "@/components/Logo";
import Nav from "./Nav";

function AppHeader() {
  return (
    <AppBar position="static" sx={{ background: "hsl(var(--slate-50))" }}>
      <Toolbar>
        <Logo />

        <Box
          sx={{
            flexGrow: 1,
            marginInlineStart: "2em",
            display: "flex",
          }}
        >
          <Nav />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default AppHeader;
